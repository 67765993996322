import Image from 'components/shared/image/image';
import useIsMobile from 'hooks/utils/useIsMobile';
import classNames from 'classnames';
import SiteLink from 'components/shared/sitelink/sitelink';
import React, { useMemo, useState } from 'react';
import { addLocaleToHref } from 'lib/utils/helpers';
import { useRouter } from 'next/router';
import WishlistButton from 'components/shared/wishlist-button';
import EvesStars from 'components/shared/eves-stars';
import UnitPrice from 'components/shared/unit-price/unitPrice';
import styles from './cardItem.module.scss';
import QuickCartSection from '../../../pages/collection/quickCartSection';

const CardItem = ({
  className,
  locale,
  onClick,
  itemData,
  globalModules,
  shopifyProduct,
  itemIndex,
  pageTitle,
  isHighlights,
  showAttributeLabel,
  attributeLabelTextColor,
  attributeLabelBackgroundColor,
  hideAddToCart,
}) => {
  const { title, type, slug, productAttributes, size } = itemData;

  const isMobile = useIsMobile();
  const router = useRouter();
  const renderCorrectImage = collectionImage => {
    if (isMobile) return collectionImage.mobile;
    return collectionImage.desktop;
  };

  const propImage = type === 'PageGeneral' ? itemData?.highlightsImage : itemData?.image;
  const [isHovered, setIsHovered] = useState(false);
  const hasLabel = showAttributeLabel && productAttributes && productAttributes[0];

  const handleImage = (pageType, item) => {
    if (pageType === 'PageGeneral') {
      return item?.highlightsImage;
    }
    if (pageType === 'PageCollection') {
      return renderCorrectImage(item?.image);
    }
    return item?.image;
  };

  const getLabelStyle = () => {
    const style = {};
    if (attributeLabelTextColor) {
      style.color = attributeLabelTextColor;
    }
    if (attributeLabelBackgroundColor) {
      style.backgroundColor = attributeLabelBackgroundColor;
    }
    return style;
  };

  const concatProductPathWithSlug = () =>
    addLocaleToHref(
      `${type === 'PageProduct' || type === 'PageProductBundle' ? 'p/' : ''}${slug}`,
      locale,
      router
    );

  return (
    <>
      {propImage && (
        <SiteLink
          locale={locale}
          draggable={false}
          href={concatProductPathWithSlug()}
          className={classNames(className, styles.carouselItem)}
          onClick={onClick}
        >
          <div
            className={classNames(styles.imageWrapper, {
              [styles.productAnimation]: isHovered,
              [styles.bottomRounded]: !hasLabel,
            })}
            onMouseEnter={() =>
              (type === 'PageProduct' || type === 'PageProductBundle') && setIsHovered(true)
            }
            onMouseLeave={() =>
              (type === 'PageProduct' || type === 'PageProductBundle') && setIsHovered(false)
            }
          >
            <Image
              className={styles.image}
              src={handleImage(
                type,
                isHovered && itemData?.productPictures?.[0]
                  ? { image: itemData.productPictures[0] }
                  : itemData
              )}
              draggable={false}
              sizes="50vw"
              layout="fill"
            />

            {type === 'PageCollection' && <p className={styles.collectionName}>{title}</p>}
            {type === 'PageGeneral' && <p className={styles.generalName}>{propImage?.title}</p>}
            {type === 'PageGeneral' && (
              <p className={styles.generalBody}> {propImage?.description}</p>
            )}
          </div>
          {hasLabel && (
            <div className={styles.attributeLabel} style={getLabelStyle()}>
              {productAttributes[0]}
            </div>
          )}

          {type === 'PageProduct' && (
            <ProductCardInfo
              product={itemData}
              globalModules={globalModules}
              itemIndex={itemIndex}
              pageTitle={pageTitle}
              isHighlights={isHighlights}
              locale={locale}
              shopifyProduct={shopifyProduct}
              size={size}
              hideAddToCart={hideAddToCart}
            />
          )}
          {type === 'PageProductBundle' && (
            <ProductBundleCardInfo
              productBundle={itemData}
              globalModules={globalModules}
              shopifyProductBundle={shopifyProduct}
              itemIndex={itemIndex}
              pageTitle={pageTitle}
              isHighlights={isHighlights}
              locale={locale}
              size={size}
            />
          )}
        </SiteLink>
      )}
    </>
  );
};

const ProductCardInfo = ({
  product,
  globalModules,
  itemIndex,
  pageTitle,
  isHighlights,
  locale,
  shopifyProduct,
  size,
  hideAddToCart,
}) => {
  const { title, productTags, shopifyData } = product;
  const {
    compareAtPrice: contentfulCompareAtPrice,
    price: contentfulPrice,
    unitPrice: contentfulUnitPrice,
  } = shopifyData?.variants?.[0] || {};
  const { productCompareAtPrice, productPrice, outOfStock, unitPrice } = shopifyProduct || {};
  const price = productPrice || contentfulPrice;
  let compareAtPrice = productCompareAtPrice || contentfulCompareAtPrice;
  if (compareAtPrice === price) compareAtPrice = null;
  const localesWithWebshop = globalModules?.settings?.localesWithWebshop;
  const salePercentage = shopifyData && (100 - (price / compareAtPrice) * 100).toFixed(0);
  const saleText = globalModules?.settings?.saleText?.text || '';
  const TitleHeader = isHighlights ? 'h3' : 'h2';

  return (
    <>
      <div className={styles.productTagsRow}>
        {!outOfStock && compareAtPrice && localesWithWebshop?.onOrOff && (
          <div className={styles.salesTag}>{`${saleText} ${salePercentage} %`}</div>
        )}
        {outOfStock && localesWithWebshop?.onOrOff && (
          <div className={styles.outOfStockTag}>{globalModules?.productpage?.outOfStock?.text}</div>
        )}
        {!outOfStock &&
          productTags?.map(productTag => <div className={styles.productTag}>{productTag}</div>)}
      </div>
      <div className={styles.productInfo}>
        <TitleHeader
          className={classNames(
            styles.productName,
            localesWithWebshop?.onOrOff && styles.withRightPadding
          )}
        >
          {title}
        </TitleHeader>
        {localesWithWebshop?.onOrOff && (
          <>
            <>
              <EvesStars eanNumber={product?.shopifyData?.variants?.[0]?.sku} locale={locale} />
              <UnitPrice
                size={size}
                price={price}
                unitPrice={unitPrice || contentfulUnitPrice}
                locale={locale}
                globalModules={globalModules}
              />
              {!hideAddToCart && (
                <QuickCartSection
                  resources={globalModules}
                  productData={product}
                  itemIndex={itemIndex}
                  pageTitle={pageTitle}
                  price={price}
                  compareAtPrice={compareAtPrice}
                  locale={locale}
                />
              )}
            </>
            <WishlistButton
              className={styles.wishlistIcon}
              productData={product}
              shopifyData={shopifyProduct}
              list={`Category: ${pageTitle}`}
              index={itemIndex}
            />
          </>
        )}
      </div>
    </>
  );
};

const ProductBundleCardInfo = ({
  productBundle,
  globalModules,
  shopifyProductBundle,
  itemIndex,
  pageTitle,
  locale,
  size,
}) => {
  const { title, bundleSlogan, productTags, bundleDiscount } = productBundle;
  const localesWithWebshop = globalModules?.settings?.localesWithWebshop;
  const saleText = globalModules?.settings?.saleText?.text || '';
  const stieglitzProductBundlePrice = 59.95;

  // if bundle contains only 1 product + dispenser (like so pure bundles) we show the reviews for that product on the bundle page
  const reviewProducts = shopifyProductBundle?.filter(
    product => !product?.productHandle?.includes('dispenser')
  );

  const combinedProductsPrices = useMemo(() => {
    let orginalCombinedPrice = shopifyProductBundle?.reduce(
      (previousItem, currentItem) =>
        previousItem + (currentItem?.productPrice ? parseFloat(currentItem?.productPrice) : 0),
      0
    );
    const isProductStieglitzInBundle =
      !!shopifyProductBundle?.filter(
        product => product?.productHandle === 'keune-x-stieglitz-giftbox' // shopify product handle
      ).length > 0;

    const doesComaparePriceExist =
      shopifyProductBundle?.filter(item => !!item?.productCompareAtPrice).length > 0;

    let combinedCompareAtPrice = doesComaparePriceExist
      ? shopifyProductBundle?.reduce(
          (previousItem, currentItem) =>
            previousItem +
            parseFloat(currentItem?.productCompareAtPrice || currentItem.productPrice || 0),
          0
        )
      : null;

    // apply custom discount percentage from contentful
    if (bundleDiscount) {
      combinedCompareAtPrice = orginalCombinedPrice;

      const roundToTwo = num => +`${Math.round(`${num}e+2`)}e-2`;

      orginalCombinedPrice = shopifyProductBundle?.reduce(
        (previousItem, currentItem) =>
          previousItem +
          (currentItem?.productPrice
            ? roundToTwo(
                currentItem?.productPrice - currentItem?.productPrice * (bundleDiscount / 100)
              )
            : 0),
        bundleDiscount >= 30 ? 0.01 : 0 // starting point. For some reason the 0.01 is constantly lost during calculations
      );
    }

    if (combinedCompareAtPrice === orginalCombinedPrice) combinedCompareAtPrice = null;

    const salesPercentages = shopifyProductBundle?.map(product => {
      const percentage = (
        100 -
        (product?.productPrice / product?.productCompareAtPrice) * 100
      ).toFixed(0);
      return Number.isFinite(percentage) ? percentage : 0;
    });

    const outOfStock = shopifyProductBundle?.filter(product => !!product?.outOfStock).length > 0;

    return {
      price: isProductStieglitzInBundle
        ? stieglitzProductBundlePrice
        : orginalCombinedPrice?.toFixed(2),
      compareAtPrice: isProductStieglitzInBundle
        ? orginalCombinedPrice?.toFixed(2)
        : combinedCompareAtPrice,
      salePercentage: salesPercentages?.reduce(
        (prev, current, i) => (parseFloat(prev) + parseFloat(current)) / (Number(i) + 1),
        0
      ),
      outOfStock,
    };
  }, [shopifyProductBundle]);

  return (
    <>
      <div className={styles.productTagsRow}>
        {combinedProductsPrices.compareAtPrice &&
          localesWithWebshop?.onOrOff &&
          combinedProductsPrices.salePercentage > 0 && (
            <div
              className={styles.salesTag}
            >{`${saleText} ${combinedProductsPrices.salePercentage} %`}</div>
          )}
        {combinedProductsPrices.outOfStock && localesWithWebshop?.onOrOff && (
          <div className={styles.outOfStockTag}>{globalModules?.productpage?.outOfStock?.text}</div>
        )}
        {!combinedProductsPrices.outOfStock &&
          productTags?.map(productTag => <div className={styles.productTag}>{productTag}</div>)}
      </div>
      <div className={styles.productInfo}>
        <h2
          className={classNames(
            styles.productName,
            localesWithWebshop?.onOrOff && styles.withRightPadding
          )}
        >
          {title}
        </h2>
        {bundleSlogan && <span className={styles.productSize}>{bundleSlogan}</span>}
        {localesWithWebshop?.onOrOff && combinedProductsPrices.price && (
          <>
            <>
              {/* EVES STARS placeholder to achieve the same height with single product on plp */}
              {reviewProducts?.length === 1 ? (
                <EvesStars eanNumber={reviewProducts[0]?.productSKU} locale={locale} />
              ) : (
                <div className={styles.evesStarsPlaceholder} />
              )}
              <UnitPrice
                price={combinedProductsPrices.price}
                locale={locale}
                globalModules={globalModules}
              />

              <QuickCartSection
                resources={globalModules}
                combinedProductsPrices={combinedProductsPrices}
                productData={productBundle}
                itemIndex={itemIndex}
                pageTitle={pageTitle}
                price={combinedProductsPrices.price.toString()}
                compareAtPrice={combinedProductsPrices.compareAtPrice?.toString()}
                locale={locale}
              />
            </>
            <WishlistButton
              className={styles.wishlistIcon}
              productData={productBundle}
              shopifyData={productBundle.cardShopifyData}
              list={`Category: ${pageTitle}`}
              index={itemIndex}
            />
          </>
        )}
      </div>
    </>
  );
};

export default CardItem;
