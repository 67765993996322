import { getStoreFrontApiCredentials, preparePayload } from 'lib/shopify';
import { PRODUCT_DATA_QUERY } from './fragments';

const parseProduct = product => {
  const salePrice = product?.salePrice?.value ? JSON.parse(product?.salePrice?.value).amount : null;
  const price = product?.variants.edges[0]?.node.price?.amount;

  const finalPrice = salePrice || price;
  const finalCompareAtPrice = salePrice
    ? price
    : product?.variants.edges[0]?.node.compareAtPrice?.amount;

  return {
    productId: product?.id,
    productSKU: product?.variants.edges[0]?.node.sku,
    productTitle: product?.title,
    productHandle: product?.handle,
    productType: product?.productType,
    productVendor: product?.productVendor?.value || '',
    productPrice: finalPrice ? parseFloat(finalPrice).toFixed(2) : null,
    currencyCode: product?.variants.edges[0]?.node.price?.currencyCode,
    productCompareAtPrice: finalCompareAtPrice ? parseFloat(finalCompareAtPrice).toFixed(2) : null,
    outOfStock: product?.tags?.includes('out-of-stock'),
    variantId: product?.variants.edges[0]?.node.id,
    relatedProducts: product?.relatedProducts?.value && JSON.parse(product?.relatedProducts?.value),
    isFreeItem: product?.tags?.includes('free-item'),
    unitPrice: {
      amount: product?.variants.edges[0]?.node.unitPrice?.amount || '',
      currencyCode: product?.variants.edges[0]?.node.unitPrice?.currencyCode || '',
      referenceValue: product?.variants.edges[0]?.node.unitPriceMeasurement?.referenceValue || '',
      referenceUnit: product?.variants.edges[0]?.node.unitPriceMeasurement?.referenceUnit || '',
    },
  };
};

const fetchShopifyChunk = async (productIds, locale) => {
  const payload = preparePayload(PRODUCT_DATA_QUERY, { ids: productIds });
  const { storeDomain, shopifyApiVersion, storeFrontAccessToken } =
    getStoreFrontApiCredentials(locale);

  const response = await fetch(`https://${storeDomain}/api/${shopifyApiVersion}/graphql`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'X-Shopify-Storefront-Access-Token': storeFrontAccessToken,
    },
    body: JSON.stringify(payload),
  });

  const shopifyData = await response.json();
  return shopifyData.data.nodes;
};

const getShopifyData = async ({ productIds, locale }) => {
  try {
    const chunkSize = 250;
    const chunks = [];
    for (let i = 0; i < productIds.length; i += chunkSize) {
      chunks.push(productIds.slice(i, i + chunkSize));
    }

    const allProducts = (
      await Promise.all(chunks.map(chunk => fetchShopifyChunk(chunk, locale)))
    ).flat();
    return allProducts.map(parseProduct);
  } catch (error) {
    return error;
  }
};

export default getShopifyData;
